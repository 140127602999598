import Page from '@/components/Pages/Page';
import DynamicComponent from '@/components/storyblok';
import storyblokService, { fetchPage } from '@/services/storyblok';

const HomePage = ({ common, content, links, preview }) => {
  return (
    <Page content={common} links={links} preview={preview}>
      <DynamicComponent content={content} />
    </Page>
  );
};

export async function getStaticProps({ preview, previewData }) {
  storyblokService.setQuery(previewData);
  storyblokService.devMode = preview;

  const [commonRes, contentRes, linksRes] = await fetchPage('home');

  if (!contentRes) {
    return {
      notFound: true,
    };
  }

  return {
    props: {
      common: commonRes ? commonRes.data.story.content : {},
      content: contentRes ? contentRes.data.story.content : {},
      links: linksRes ? linksRes.data.links : {},
      preview: preview || null,
    },
  };
}

export default HomePage;
